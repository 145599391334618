
  import ScreenSize from '@/mixins/ScreenSize';
  import 'video.js/dist/video-js.css';
  import 'videojs-record/dist/css/videojs.record.css';
  import videojs from 'video.js';
  import 'webrtc-adapter';
  import 'videojs-record/dist/videojs.record.js';
  import {v4 as uuid4} from 'uuid';

  export default {
    mixins: [ScreenSize],
    data() {
      return {
        devices: null,
        deviceId: null,
        deviceOptions: [],
        selectedOption: {},
        player: null,
        disabledChanger: false,
        htmlId: 'recorder_' + uuid4(),
        camera: 'user',
        options: {
          controls: true,
          autoplay: false,
          fluid: false,
          loop: false,
          width: 320,
          height: 320,
          bigPlayButton: true,
          controlBar: {
            volumePanel: false,
          },
          plugins: {
            record: {
              audio: true,
              debug: false,
              maxLength: 60,
              maxFileSize: 29000000,
              timeSlice: 1000,
              video: this.isDesktopOrTablet
                ? {
                    aspectRatio: {ideal: 0.75},
                  }
                : true,
            },
          },
        },
      };
    },
    mounted() {
      this.player = videojs('#' + this.htmlId, this.options);
      this.disabledChanger = true;

      this.player.one('deviceReady', () => {
        this.player.record().enumerateDevices();
      });

      this.player.on('enumerateReady', () => {
        this.devices = this.player.record().devices;

        this.devices.map((device) => {
          if (device.kind === 'videoinput') {
            this.deviceOptions.push(device);
          }
        });
        this.selectedOption = this.deviceOptions[0];
        this.disabledChanger = false;
      });

      this.player.on('startRecord', () => {
        this.disabledChanger = true;
      });

      // user completed recording and stream is available
      this.player.on('finishRecord', () => {
        this.disabledChanger = false;
        this.$notifications.showNotification({
          message: this.$t('videos.recording_finished'),
          color: 'success',
          timeout: 10000,
        });
        this.$emit('finished-recording', this.player.recordedData);
      });

      // error handling
      this.player.on('error', (element, error) => {
        this.$notifications.showNotification({
          message: 'error:' + error,
          color: 'error',
        });
      });

      this.player.on('deviceError', () => {
        if (this.player.deviceErrorCode.message === 'Permission denied') {
          this.$notifications.showNotification({
            message: this.$t('videos.disallowed'),
            color: 'error',
          });
        } else {
          this.$notifications.showNotification({
            message: 'device error:' + this.player.deviceErrorCode,
            color: 'error',
          });
        }
      });
    },
    beforeDestroy() {
      if (this.player) {
        this.player.dispose();
      }
    },
    methods: {
      changeCamera() {
        const device = this.devices.find((obj) => {
          return obj.label === this.selectedOption;
        });
        try {
          // change video input device
          this.player.record().setVideoInput(device.deviceId);
        } catch (error) {
          //console.warn(error);
        }
      },
    },
  };
