import { render, staticRenderFns } from "./VideoJSRecorder.vue?vue&type=template&id=7669f064&"
import script from "./VideoJSRecorder.vue?vue&type=script&lang=js&"
export * from "./VideoJSRecorder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
